import React, {useState, useRef, useEffect, useCallback} from "react";
import Form, {Item, Label, SimpleItem} from "devextreme-react/form";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import RadioGroup from "devextreme-react/radio-group";
import Button from "devextreme-react/button";
import appInfo from "../../app-info";
import {handleErrors} from "../configuration/helpers/globalFunctions";
import {saveAs} from "file-saver";
import axios from "axios";
import {alert} from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import {LoadPanel} from "devextreme-react/load-panel";
import {useAuth} from "../../contexts/auth";
import {Template} from "devextreme-react/core/template";

export default () => {
	const {user} = useAuth();
	const rootUrl = {
		url: `${appInfo.shippingURL}ShipmentPlanProgressReport/Export`
	};
	let token = user.data.token;
	let language = user.data.userData.language;
	let formatDate = user.data.userData.formatDate;
	const progessReportParams = [
		"plantId",
		"sewsPartNo",
		"shipToWhse",
		"customerCode",
		"year",
		"month",
		"from",
		"to",
		"status",
		"frequency",
		"type",
		"exportDate",
		"exportTime"
	];
	const [plantDetails, setPlantDetails] = useState({
		sews_part: "",
		customer_code: "",
		ship_to_whse: ""
	});
	/* Editor Options - INFO: Separate editor options due performance */
	const sews_part_EditorOptions= {
		maxLength: 18
	}
	const ship_to_whse_EditorOptions = {
		maxLength: 5
	};

	const radioOptions = ["Shipped", "Produced"];
	const [radioOptionsSelected, setRadioOptionsSelected] = useState(radioOptions[0]);
	const radioOptions2 = ["Daily", "Weekly", "Monthly"];
	const [radioOptionsSelected2, setRadioOptionsSelected2] = useState(radioOptions2[0]);
	const radioOptions3 = ["Progress Report", "Plan Error Report"];
	const [radioOptionsSelected3, setRadioOptionsSelected3] = useState(radioOptions3[0]);
	const [disableSearchBtn, setdisableSearchBtn] = useState(false);

	const [disableFieldsDate, setDisableFieldsDate] = useState(false);
	const [disableFieldsFromTo, setDisableFieldsFromTo] = useState(true);
	const [disableFieldsPeriod, setDisableFieldsPeriod] = useState(true);

	const [years, setYears] = useState(null); //keep the years list
	const [currentYear, setCurrentYear] = useState(null); // this one will set the value in the dropdown
	const [initialYear, setInitialYear] = useState(null); //this one will be used to reset the initial values

	const [months, setMonths] = useState(null); //keep the month list
	const [currentMonth, setCurrentMonth] = useState(null); // this one will set the value in the dropdown
	const [initialMonth, setInitialMonth] = useState(null); //this one will be used to reset the initial values
	const [monthFormat, setMonthFormat] = useState(null); //this one will be used to reset the initial values

	const currentDate = new Date().toISOString().split("T")[0].replace(/-/g, "/");
	const [fromDateVal, setFromDateVal] = useState(currentDate);
	const [toDateVal, setToDateVal] = useState(currentDate);

	const [plantIDSelected, setPlantIDSelected] = useState();

	const [minDate, setMinDate] = useState(new Date());
	const [maxDate, setMaxDate] = useState(new Date()); 
	const [loadPanelVisible, setLoadPanelVisible] = useState(false);
	const [planData, setData] = useState({
		labelLocation: "top",
		readOnly: false,
		showColon: true,
		minColWidth: 300,
		colCount: 4
	});

	const [plantDataSource, setPlantDataSource] = useState(null);

	useEffect(() => {
		const abortController = new AbortController();
		(async () => {
			let getYear = `${appInfo.shippingURL}ShipmentPlanProgressReport/Years`;
			let getMonths = `${appInfo.shippingURL}ShipmentPlanProgressReport/Months`;
			let getActivePlant = `${appInfo.shippingURL}ShipmentPlanProgressReport/ActivePlant`;
			let getPlants = `${appInfo.shippingURL}ShipmentPlanProgressReport/Plants`;

			await fetch(getPlants, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`},
				signal: abortController.signal
			})
				.then((response) => handleErrors(response))
				.then((response) => response.json())
				.then((res) => {
					setPlantDataSource(res.data);
				});

			await fetch(getYear, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`},
				signal: abortController.signal
			})
				.then((response) => handleErrors(response))
				.then((response) => response.json())
				.then((result) => {
					let yearsList = result.data.map((year) => {
						return year.value;
					});
					let currYear = new Date().getFullYear();
					currYear = currYear.toString();
					let yearArrayPosition = yearsList.indexOf(currYear);
					setCurrentYear(yearsList[yearArrayPosition]);
					setInitialYear(yearsList[yearArrayPosition]);
					setYears(yearsList);
				})
				.catch((error) => {
					if (error.name === "AbortError") {
						console.warn("ShipmentPlanProgressReport fail getting year list");
					}
				});

			await fetch(getMonths, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`},
				signal: abortController.signal
			})
				.then((response) => response.json())
				.then((result) => {
					let monthList = result.data.map((month) => {
						return month.value;
					});
					let currMonth = new Date().getMonth();
					setCurrentMonth(monthList[currMonth]);
					setInitialMonth(monthList[currMonth]);
					setMonths(monthList);
				})
				.catch((error) => {
					if (error.name === "AbortError") {
						console.warn("ShipmentPlanProgressReport fail getting month list");
					}
				});

			await fetch(getActivePlant, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`},
				signal: abortController.signal
			})
				.then((result) => result.json())
				.then((result) => {
					setPlantIDSelected(result[0].plantId);
				})
				.catch((error) => {
					if (error.name === "AbortError") {
						console.warn("ShipmentPlanProgressReport fail getting active plant");
					}
				});

			return () => {
				// Abort the request when the component unmounts or when a dependency changes
				abortController.abort();
			};
		})();
	}, []);
	const ItemPlant = (args) => {
		return (
			<div>
				{args.plantId} - {args.value}
			</div>
		);
	};
	const selectEditorOptions = {
		dataSource: plantDataSource,
		valueExpr: "plantId",
		value: plantIDSelected,
		displayExpr: "plantId",
		searchEnabled: true,
		searchExpr: ["plantId", "value"],
		searchMode: "contains",
		itemTemplate: "ItemPlant",
		showClearButton: true,
		onSelectionChanged: (e) => {
			if(e.selectedItem){
				setPlantIDSelected(e.selectedItem.plantId);
			}else{
				setPlantIDSelected('');
			}
		}
	};
	const sumbitAction = async () => {
		/* Get current date and time to send endpoint */
		let today = new Date();

		let hour = today.getHours();
		let minutes = today.getMinutes();
		let monthReport = today.getMonth() + 1;
		let yearReport = today.getUTCFullYear();
		let dayReport = today.getDate();

		let time = `${hour}:${minutes}`;
		let fullDateReport = `${yearReport}/${monthReport}/${dayReport}`;
		let from = new Date(fromDateVal);
		let to = new Date(toDateVal);
		let difference_in_time = to.getTime() - from.getTime();
		let difference_in_days = difference_in_time / (1000 * 3600 * 24);
		if (difference_in_days >= 31) {
			return alert("[20100]Date Range Can not be more than 31 Days!");
		}
		let fileName;
		let status;
		let frequency;
		let type;
		let title;
		let reportDate;
		let typeName;
		if (radioOptionsSelected === "Shipped") {
			status = 0;
			reportDate = "";
			typeName = "Shipment";
		} else if (radioOptionsSelected === "Produced") {
			status = 1;
			reportDate = radioOptionsSelected2;
			typeName = "Production";
		}

		if (radioOptionsSelected2 === "Daily") {
			frequency = 0;
		} else if (radioOptionsSelected2 === "Weekly") {
			frequency = 1;
		} else if (radioOptionsSelected2 === "Monthly") {
			frequency = 2;
		}

		if (radioOptionsSelected3 === "Progress Report") {
			type = 0;
			title = "";
		} else if (radioOptionsSelected3 === "Plan Error Report") {
			reportDate = "";
			type = 1;
			title = "Err ";
		}

		const data = {
			plantId: plantIDSelected,
			sewsPartNo: plantDetails.sews_part,
			shipToWhse: plantDetails.ship_to_whse,
			customerCode: plantDetails.customer_code,
			year: currentYear,
			month: monthFormat,
			from: fromDateVal,
			to:toDateVal,
			status: status,
			frequency: frequency,
			type: type,
			exportDate: fullDateReport,
			exportTime: encodeURIComponent(time)
		};

		fileName =
			language === "ES"
				? (fileName = `${typeName} ${title} Plan ${reportDate} Progress_${data.plantId}_${dayReport}-${monthReport}-${yearReport}.xlsx`)
				: (fileName = `${typeName} ${title}Plan ${reportDate} Progress_${data.plantId}_${monthReport}-${dayReport}-${yearReport}.xlsx`);

		/* Build URL before send  */
		const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
		let params = "?";
		progessReportParams.forEach(function (i) {
			if (i in data && isNotEmpty(data[i])) {
				params += `${i}=${JSON.stringify(data[i])}&`;
			}
		});
		params = params.replace(/"/g, "");
		params = params.slice(0, -1);
		let exportURL = `${rootUrl.url}${params}`;

		getDownloadFile(exportURL, token, fileName);
	};
	const getDownloadFile = async (url, token, fileName) => {
		setLoadPanelVisible(true);
		return await axios
			.get(url, {
				responseType: "blob",
				headers: {
					Authorization: `Bearer ${token}`,
					"Accept-Language": `${language}`,
					"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				},
				url: url
			})
			.then((response) => {
				return response.data;
			})
			.then((buffer) => {
				hideLoadPanel();
				if (buffer.size > 0) {
					return saveAs(new Blob([buffer], {type: "application/octet-stream"}), fileName);
				} else {
					return notify("[20024]No records found", "warning", 2000);
				}
			})
			.catch(async (e) => {
				hideLoadPanel();
				let statusCode = e.response.status;
				let responseObj = await e.response.data.text();
				let filteredMessage = responseObj.replace(/{"message":"/g, "");
				return alert(filteredMessage.replace(/"}/g, ""));
			});
	};
	const hideLoadPanel = () => {
		setLoadPanelVisible(false);
	};
	const clearFilters = () => {
		setPlantDetails({sews_part: "", customer_code: "", ship_to_whse: ""});
		setCurrentMonth(initialMonth);
		setCurrentYear(initialYear);
		setToDateVal(currentDate);
		setFromDateVal(currentDate);
		setRadioOptionsSelected(radioOptions[0]);
		setRadioOptionsSelected2(radioOptions2[0]);
		setRadioOptionsSelected3(radioOptions3[0]);
		setDisableFieldsFromTo(true);
		setDisableFieldsPeriod(true);
	};
	const typeOnValueChange = (e) => {
		e.promise = setRadioOptionsSelected(e.value);
		if (e.value === "Shipped") {
			setRadioOptionsSelected2(radioOptions2[0]);
			setDisableFieldsDate(false);
			setDisableFieldsFromTo(true);
			setDisableFieldsPeriod(true);
		} else {
			setDisableFieldsDate(true);
			setDisableFieldsFromTo(false);
			setDisableFieldsPeriod(false);
		}
	};
	const periodOnValueChange = (e) => {
		e.promise = setRadioOptionsSelected2(e.value);

		if (e.value === "Daily") {
			setDisableFieldsDate(true);
			setDisableFieldsFromTo(false);
			setDisableFieldsPeriod(false);
		} else if (e.value === "Weekly" || e.value === "Monthly") {
			setDisableFieldsDate(false);
			setDisableFieldsFromTo(true);
			setDisableFieldsPeriod(false);
		}
	};
	const ppOnValueChange = (e) => {
		e.promise = setRadioOptionsSelected3(e.value);
	};  
	const onChangeMonth = (e) => {
		let monthNum;
		switch (e.value) {
			case "Jan":
				monthNum = "01";
				break;
			case "Feb":
				monthNum = "02";
				break;
			case "Mar":
				monthNum = "03";
				break;
			case "Apr":
				monthNum = "04";
				break;
			case "May":
				monthNum = "05";
				break;
			case "Jun":
				monthNum = "06";
				break;
			case "Jul":
				monthNum = "07";
				break;
			case "Aug":
				monthNum = "08";
				break;
			case "Sep":
				monthNum = "09";
				break;
			case "Oct":
				monthNum = "10";
				break;
			case "Nov":
				monthNum = "11";
				break;
			case "Dec":
				monthNum = "12";
				break;
		}

		setMonthFormat(monthNum);

		if (months !== null) {
			let positonMonth = parseInt(monthNum);
			positonMonth = positonMonth - 1;
			setCurrentMonth(months[positonMonth]);
		}
	};
	const onChangeYear = (e) => {
		setCurrentYear(e.value);
	};
	const fromDateOnValueChanged = useCallback((e)=>  {
		let newFrom = new Date(fromDateVal);
		let newTo = new Date(toDateVal);
		if (newFrom <= newTo) {
			setdisableSearchBtn(false);
		} else {
			setdisableSearchBtn(true);
		}
		setFromDateVal(e.value);
		setMinDate(e.value);
		
		let newDay = new Date(e.value);
		newDay.setDate(newDay.getDate() + 30);
		setMaxDate(newDay);
	},[]);

	const toDateOnValueChanged = useCallback((e)=>{
		let newFrom = new Date(fromDateVal);
		let newTo = new Date(toDateVal);
		if (newFrom <= newTo) {
			setdisableSearchBtn(false);
		} else {
			setdisableSearchBtn(true);
		}
		setToDateVal(e.value);
	})

	const radioOptions_EditorOptions ={
		items: radioOptions,
		onValueChanged: typeOnValueChange,
		layout:'horizontal',
		value:radioOptionsSelected
	}
	const radioOptions2_EditorOptions = {
		items :radioOptions2,
		layout:'horizontal',
		onValueChanged:periodOnValueChange,
		disabled:disableFieldsPeriod,
		value:radioOptionsSelected2
	}
	const radioOptions3_EditorOptions = {
		items: radioOptions3,
		layout: 'horizontal',
		onValueChanged: ppOnValueChange,
		value : radioOptionsSelected3
	}
	const month_EditorOptions = {
		placeholder:'Month',
		disabled: disableFieldsDate,
		searchEnabled: true,
		onValueChanged: onChangeMonth,
		items: months,
		value: currentMonth
	}
	const year_EditorOptions = {
		placeholder: 'Year',
		disabled:disableFieldsDate,
		searchEnabled:true,
		items: years,
		onValueChanged: onChangeYear,
		value: currentYear
	}
	const get30DaysMaxDate = useCallback(() => {
		setMinDate(fromDateVal);
		let newDay = new Date(fromDateVal);
		newDay.setDate(newDay.getDate() + 30);
		setMaxDate(newDay);
	},[fromDateVal]);

	const dateFrom_EditorOptions = {
		value: fromDateVal,
		type:'date',
		id: "fromDate",
		displayFormat: formatDate,
		disabled :disableFieldsFromTo,
		pickerType: 'calendar',
		useMaskBehavior: true,
		placeholder: 'Date From',
		dateSerializationFormat: 'yyyy/MM/dd',
		onValueChanged: fromDateOnValueChanged
	}
	const dateTo_EditorOptions = {
		value: toDateVal,
		type:'date',
		id: "toDate",
		displayFormat: formatDate,
		disabled: disableFieldsFromTo,
		placeholder: 'To',
		dateSerializationFormat: 'yyyy/MM/dd',
		onValueChanged: toDateOnValueChanged,
		onInitialized :() => {
			get30DaysMaxDate();
		},
		min: minDate,
		max: maxDate,
		pickerType: 'calendar'
	}
	return (
		<React.Fragment>
			<div className={"content-block"}>
				<h2>Shipment Plan Progress Report </h2>
				<div>
					<div className='dx-field'>
						<Button
							icon='export'
							text='  Export'
							disabled={disableSearchBtn}
							onClick={sumbitAction}
						/>
					</div>
					<div className='dx-field'>
						<Button
							icon='remove'
							text='  Cancel'
							elementAttr={{id: "cancelButton"}}
							onClick={clearFilters}
						/>
					</div>
					<div className='dx-field'>
						<div className={"total-label"}>
							<span>Mode: </span>
							<label id='modeLabel'> View </label>
						</div>
					</div>
				</div>
				<div id='form-report'>
					<div className='widget-container'>
						<Form
							id='formPlantMaster'
							formData={plantDetails}
							readOnly={planData.readOnly}
							showColonAfterLabel={planData.showColon}
							labelLocation={planData.labelLocation}
							minColWidth={planData.minColWidth}
							colCount={planData.colCount}
							width={planData.width}
							validationGroup='PlantData'>
							<Item dataField='plantId' visible={false} />
							<Item
								dataField='plant_id'
								colSpan={4}
								editorType='dxSelectBox'
								editorOptions={selectEditorOptions}>
								<Template name='ItemPlant' render={ItemPlant}/>
								<Label text='Plant Id' />
							</Item>
							<Item dataField='sews_part' colSpan={2} editorOptions={sews_part_EditorOptions}>
								<Label text='SEWS Part No.' />
							</Item>
							<Item dataField='customer_code' colSpan={2} editorOptions={{maxLength: 4}}>
								<Label text='Customer Code' />
							</Item>
							<Item colSpan={1} editorType="dxSelectBox" editorOptions={month_EditorOptions} >
								<Label text='Date'/>
							</Item>
							<Item colSpan={1} editorType="dxSelectBox" editorOptions={year_EditorOptions} >
								<Label text=' '></Label>
							</Item>
							<Item dataField='ship_to_whse' 
								colSpan={2} 
								editorOptions={ship_to_whse_EditorOptions}>
								<Label text='Ship To Whse' />
							</Item>
							<Item dataField='date_from' 
								editorType="dxDateBox" 
								editorOptions={dateFrom_EditorOptions}>
								<Label text='Date From' />
							</Item>
							 <Item dataField='date_to' 
							 	editorType="dxDateBox"
								editorOptions = {dateTo_EditorOptions}>
								<Label text='To' />
							</Item>
							<Item colSpan={2} editorType="dxRadioGroup" editorOptions={radioOptions_EditorOptions}/>
							<Item colSpan={2} editorType="dxRadioGroup" editorOptions={radioOptions2_EditorOptions}/>
							<Item colSpan={4} editorType="dxRadioGroup" editorOptions={radioOptions3_EditorOptions}/>
						</Form>
					</div>
				</div>
			</div>
			<LoadPanel
				visible={loadPanelVisible}
				onHiding={hideLoadPanel}
				showIndicator={true}
				shading={true}
				showPane={true}
			/>
		</React.Fragment>
	);
};
